import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import FAQHero from 'src/components/sections/faqs/Hero'
import Accordion from 'src/components/interactivity/Accordion'
import ContactUs from 'src/components/sections/faqs/ContactUs'

export default function FAQs(props) {
    const { setTheme } = useContext(ThemeContext);
    useEffect(() => setTheme('normal'), [])
    const { t } = useTranslation()

    const faqsList = t('faqs.questions', { returnObject: true });
    return (
        <MarketingLayout {...props} title={t('faqs.title')}>
            <FAQHero/>
            <Accordion data={faqsList}>{t('faqs.questions&answers')}</Accordion>
            <ContactUs/>
        </MarketingLayout>
    )
}
