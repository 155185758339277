import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Heading from 'src/components/typography/Heading'
import 'src/components/buttons/Link.css'

const UNDERLINED = "text-white text-lg mx-4 inline-block md:text-xl link-underline secondary cursor-pointer"

export default function HowItWorksHero() {
    const { t } = useTranslation();

    function showIntercom () {
        if(window.Intercom) {
            window.Intercom('show');
        }
    }

    return (
        <div className="px-4 bg-baby-blue-600 w-full py-12 md:pb-24">
            <div className="mx-auto md:w-2/3 text-center">
                <Heading type='head-title-wrap' className="mb-10">
                    <span className="text-white">{t('faqs.anyQuestions')} </span>
                    <span className="text-egg-shell-600">{t('faqs.weAreHereToHelp')}</span>
                </Heading>
                <div>
                    <span
                        className={classNames(UNDERLINED)}
                        onClick={() => scrollTo('#faqs')}>
                        {t('faqs.questions&answers')}
                    </span>
                    <span
                        className={classNames(UNDERLINED)}
                        onClick={() => {
                            scrollTo('#contact-us')
                            showIntercom()
                        }}>
                        {t('faqs.contactUs')}
                    </span>
                </div>
            </div>
        </div>
    );
}
