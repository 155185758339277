import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'

export default function ContactUs() {
    const { t } = useTranslation()

    return (
        <div id="contact-us" className="mx-auto px-4 py-16 flex justify-center items-center flex-col">
            <Heading type="sub-title" className="mb-4">{t('faqs.contactUs')}</Heading>
            <a href="mailto:support@whambam.be"
               target="_blank"
               rel="noreferrer">
                support@whambam.be
            </a>
            <a href="tel: +32 493 44 46 45"
               target="_blank"
               rel="noreferrer">
                +32 493 44 46 45
            </a>
            <p className="mt-10">Ankerrui 20,</p>
            <p>2000 Antwerpen</p>
            <p>Belgium</p>
        </div>
    )
}
